import React, { Fragment } from 'react'
import { Global } from '@emotion/react'
import { globalStyles } from '../components/layout.styles'
import SEO from "../components/Seo"
import NavBar from '../components/NavBar3'
import Footer from '../components/Footer'
import Button2 from '../components/AtividadesProjetosBtn'
import PDF from '../documents/_2666 -Na-escola-a-gente-constroi-muitas-historias-capa_contracapa.indd.pdf'
import { 
  section,
  container,
  content,
} from '../components/styles/Base.styles'


const BlogItem = props => (
  <Fragment>
    <Global styles={globalStyles} />
    <SEO title="Coletânea 2021 - “Na escola, a gente constrói muitas histórias.”" />
    <NavBar />
    {/* <div css={HeaderBanner}>
      <img src={Banner} alt='' style={{ width: '100%' }} />
    </div> */}
    <div css={[section, content]} style={{ paddingBottom: 0, marginTop: 112 }}>
      <div css={container}>
        <h1>Coletânea 2021 - “Na escola, a gente constrói muitas histórias.”</h1>
        <p>Várias são as vantagens que a leitura traz para uma criança. O aumento de seu repertório, a identificação com os personagens da narrativa, a expansão do seu imaginário e o conhecimento de mundo são alguns pontos que podemos destacar. Dentre as várias atividades do Projeto Leitura Criativa, a experiência de autoria, uma ação chamada “Coleção Amarelinha”, traz o conceito de trabalhar o “processo” na vida dos (as) pequenos (as).</p>
        <p>Das turmas kids1 ao 1 ° ano do Fundamental, em 2021, vários temas surgiram e tornaram os dias memoráveis, dando origem a uma coletânea rica em expressão, enredo e algumas problemáticas dos dias atuais apareceram nas criações. Além do protagonismo na elaboração de forma coletiva, as crianças fizeram um verdadeiro tour pela escola para juntas chegarem à “Na escola, a gente constrói muitas histórias”.</p>
        <p>Tudo isso, potencializa o gosto pela leitura construindo hábitos que abrem um mundo de fantasia, despertando assim o encantamento da criança pelo livro, pois ela se sente parte da criação.</p>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <a href={PDF}><Button2>baixe nosso e-book</Button2></a>
        </div>
        <div style={{ paddingBottom: 112 }} />
      </div>
    </div>
    <Footer />
  </Fragment>
)

export default BlogItem